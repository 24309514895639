import { navigate } from 'gatsby'
import React from 'react'
import { handleBooking } from './functions'

const Footer = (props) => {
    const { toggleModal } = props
    return (
        <footer>
            <div className="container">
                <div className="footer-container">
                    <div className="copyright">
                        <p>© Copyright 2021 Phramebooth</p>
                    </div>
                    <div className="footer-menu">
                        <ul>
                            <li><a href={false} onClick={handleBooking}>Reserve a Booth</a></li>
                            <li><a href={false} onClick={toggleModal}>Contact Us</a></li>
                            <li><a href={false} onClick={() => navigate('/login')}>Login</a></li>
                            {/* <li><a href={false}>Register</a></li> */}
                            <li><a href={false}>Terms of Service</a></li>
                            <li><a href={false}>Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
