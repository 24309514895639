import React from 'react'
import PlacesAutocomplete from 'react-places-autocomplete';
import "./style.css"

function LocationMarker(props) {
    const { value, handleChange, handleSelect } = props;
    return (
        <PlacesAutocomplete
            value={value}
            onChange={handleChange}
            onSelect={handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <>
                    <input
                        {...getInputProps({
                            placeholder: '',
                            className: "form-control",
                        })}
                        placeholder="Select Location"
                    />
                    <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </PlacesAutocomplete>
    )
}

export default LocationMarker
