const { openPopupWidget } = require("react-calendly");
const options = {
    url: 'https://calendly.com/phrameboothrentals/2hour',
    text: 'Schedule time with me',
    color: '#0069ff',
    textColor: '#ffffff',
    branding: true
}
export const handleBooking = () => openPopupWidget(options);

// <!-- Calendly badge widget begin -->
// <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet">
// <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>
// <script type="text/javascript">window.onload = function() { 
    // Calendly.initBadgeWidget({ url: 'https://calendly.com/phrameboothrentals/2hour', text: 'Schedule time with me', color: '#0069ff', textColor: '#ffffff', branding: true }); }</script>
// <!-- Calendly badge widget end --></link>