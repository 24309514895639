import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import "../../../../assets/css/landingstyles.css"
import LocationMarker from '../../../../components/common/LocationMarker'

function LocationModal(props) {
    const { onSubmit, show, onHide } = props
    const [hasError, setHasError] = useState(false)
    const [location, setLocation] = useState("")
    const [latLng, setLatLng] = useState(null)
    const [isfetching, setIsfetching] = useState(false)
    const handleChange = (e) => {
        setLocation(e)
    }
    const handleSelect = (e) => {
        try {
            setIsfetching(true)
            geocodeByAddress(e)
                .then(results => getLatLng(results[0]))
                .then(latLng => {
                    setLatLng(latLng)
                    setIsfetching(false)
                })
                .catch(error => {
                    setIsfetching(false)
                    console.error('Error', error)
                });
        } catch (error) {
            console.log(e => console.log(e))
            setIsfetching(false)
        }
        setLocation(e)
    }

    const handleSubmit = () => {
        if (latLng && location) {
            onSubmit(latLng)
            handleHide()
        } else {
            setHasError(true)
        }
    }

    const handleHide = () => {
        setHasError(false)
        setLocation("")
        setLatLng(null)
        onHide()
    }

    return (
        <Modal
            centered
            size={"md"}
            show={show}
            onHide={handleHide}
        >
            <Modal.Header closeButton>
                <Modal.Title>Delivery Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="landing-cover-modal">
                    <div className="contact section-gap" id="contactus">
                        <div className="container">
                            <p className="content-text" style={{ marginBottom: "10px" }}>
                                Let us know your delivery address.
                            </p>
                            {hasError &&
                                <Alert key={1} variant="danger">
                                    Delivery address must required.
                                </Alert>
                            }
                            <form method="post">
                                <div>
                                    <div className="form-row">
                                    </div>
                                    <div className="form-row location-row">
                                        <LocationMarker
                                            value={location}
                                            handleChange={handleChange}
                                            handleSelect={handleSelect}
                                        />
                                    </div>
                                    <div className="btn-wrap">
                                        <button className="sbtn btn-close" type="button" onClick={handleHide}>Close</button>
                                        <input
                                            disabled={isfetching}
                                            type="button"
                                            className="sbtn"
                                            defaultValue="Submit"
                                            onClick={handleSubmit} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LocationModal
