import React, { Fragment, useEffect, useState } from 'react'
import Footer from '../Footer';
import LandingHeader from '../LandingHeader';
import { getDistance, convertDistance } from 'geolib';
import { CustomSweetAlert } from '../../../../components/common';
import { handleBooking } from '../functions'
import LocationModal from './LocationModal';

function LandingLayout(props) {

    const { children, toggleModal } = props;
    const JacksonCity = {
        latitude: 32.298756,
        longitude: -90.184807,
    }
    const [show, setShow] = useState(false)
    const [haslocation, setHaslocation] = useState(false)
    const [isFarFromJackson, setIsFarFromJackson] = useState(false)
    const [SweetAlert, setSweetAlert] = useState({
        show: false,
        type: "info",
        title: "",
        message: "Sorry, we are currently not servicing your area. We are working on expanding our team, so that we can provide the PhrameBooth Experience in more areas",
        onConfirm: () => { setSweetAlert({ ...SweetAlert, show: false }); },
        onCancel: () => { setSweetAlert({ ...SweetAlert, show: false }) }
    });

    const GetDistanceFromJackson = (position) => {
        const distance = getDistance(position, JacksonCity)
        if (convertDistance(distance, 'mi') > 30) {
            setIsFarFromJackson(true)
            return false
        } else {
            setIsFarFromJackson(false)
            return true
        }
    }

    const reserveABooth = () => {
        if (!haslocation) {
            setShow(true)
        } else {
            if (haslocation && isFarFromJackson) {
                setSweetAlert({
                    ...SweetAlert,
                    show: true
                })
            } else {
                handleBooking()
            }
        }
    }

    const valueFromLocationModal = (position) => {
        if (GetDistanceFromJackson(position)) {
            handleBooking()
        } else {
            setSweetAlert({
                ...SweetAlert,
                show: true
            })
        }
    }

    const handleClose = () => {
        setShow(false)
    }

    return (
        <Fragment>
            <LandingHeader reserveABooth={reserveABooth} />
            <div>
                {children}
            </div>
            <Footer toggleModal={toggleModal} />
            <CustomSweetAlert {...SweetAlert} />
            <LocationModal
                show={show}
                onHide={handleClose}
                onSubmit={valueFromLocationModal} />
        </Fragment>
    )
}


export default LandingLayout
