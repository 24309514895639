import React, { useState } from 'react'
import menu from '../../../assets/images/landingImages/menu-icon.png'
import logo from '../../../assets/images/landingImages/logo.png'
import arrow from '../../../assets/images/landingImages/menu-arrow.png'
import cross from '../../../assets/images/cross.png'
import hamburger from '../../../assets/images/hamburger.png'
import { navigate } from 'gatsby'

const LandingHeader = (props) => {
  const { reserveABooth } = props
  const [hamBurgerOpen, setHamBurgerOpen] = useState(false)

  const handleHamburger = () => {
    setHamBurgerOpen(!hamBurgerOpen)
  }

  return (
    <header>
      <div className="header-top">
        <p>Servicing the Jackson, MS and surrounding areas</p>
      </div>
      <div className="header-middle">
        <div className="container">
          <div className="row">
            <div className="logo">
              <a href={false} className="header-main-logo" onClick={() => navigate('/')}>
                <img src={logo} alt="Logo" />
              </a>
            </div>
            <div className="menu head-menu">
              <ul>
                <li><a href={false} onClick={() => navigate('/')}>Home</a></li>
                {/* <li><a href={false} onClick={() => navigate('/gallery')}>Gallery</a></li> */}
                <li><a href={false} onClick={reserveABooth}><span><img src={menu} /></span> Reserve a Booth </a></li>
              </ul>
            </div>
            <div className="login head-login">
              <ul>
                <li><a href={false} onClick={() => navigate("/login")}><span><img src={arrow} /></span> Login </a></li>
              </ul>
            </div>

            {hamBurgerOpen && <div className="mob-menu">
              <div className="menu">
                <ul>
                  <li><a href={false} onClick={() => navigate('/')}>Home</a></li>
                  {/* <li><a href={false} onClick={() => navigate('/gallery')}>Gallery</a></li> */}
                  {/* <li><a href={false} onClick={() => navigate('/reserve-booth')}><span><img src={menu} /></span> Reserve a Booth </a></li> */}
                  <li><a href={false} onClick={() => navigate("/login")}><span><img src={arrow} /></span> Login </a></li>
                </ul>
              </div>
            </div>}
            {/* <div onClick={handleHamburger} className="hamburger">
              <img src={hamBurgerOpen ? cross : hamburger} alt="hamburger-image" />
            </div> */}
          </div>
        </div>
      </div>
    </header>
  )
}

export default LandingHeader
